import React, { useCallback, useState, useEffect, useRef } from 'react';
// import { useHistory } from "react-router-dom";
import { Page, Layout, Card, FormLayout, TextField, PageActions, Form, Select, Banner, Button, Text, Badge, InlineStack, Box } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import { environment } from './environments/environment.prod';
import useLoader from './Loader/UseLoader';
import useSuccessToast from './Toast/UseSuccessToast';
import useErrorToast from './Toast/UseErrorToast';
import Api from './Services/CommonServices';
// import { unstable_HistoryRouter } from 'react-router-dom';
// import * as storage from "./Auth/storage";
const api = new Api();
const endpoint = environment.serverUrl;
export function GlobalSetting() {
  // const history = unstable_HistoryRouter();
  const [active, setActive] = useState();
  const [btnLoad, setBtnLoad] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const shopOrigin = useRef('');
  // const [shopUrl,setShopUrl] = useState('');
  const handleToggle = useCallback((value) => { setActive((active) => !active); handleFormToggle() }, []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [selected, setSelected] = useState('true');
  const [carrierServiceId, setCarrierServiceId] = useState(1);
  const [pageLoad, setPageLoad] = useState(false);
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [errorD, setErrorD] = useState(false);
  const [errorSn, setErrorSn] = useState(false);
  const [unit, setUnit] = useState('metric');
  const [authUrl, setAuthUrl] = useState('');
  const [appUpdate, setAppUpdate] = useState(false);
  const handleUnitChange = useCallback((value) => setUnit(value), []);
  const [value, setValue] = useState({
    store: '',
    serviceDiscovery: 'true',
    storeStatus: 'Disable'
  });
  const [distanceMatrixvalue, setDistanceMatrix] = useState({
    description: '',
    serviceCode: '',
    serviceName: '',
    store: '',
    serviceDiscovery: true,
    units: ''
  });
  const [connected, setConnected] = useState(false);
  const handleAction = useCallback(() => {
    setConnected((connected) => !connected);
  }, [connected]);
  const buttonText = connected ? 'Configure' : 'Configure';
  const details = connected ? 'Distance settings configured' : 'Click Configure to enable distance';

  const [shiprocketConnected, setShiprocketConnected] = useState(false);
  const handleShiprocketAction = useCallback(() => {
    setShiprocketConnected((shiprocketConnected) => !shiprocketConnected);
  }, [shiprocketConnected]);
  const shiprocketBtnText = shiprocketConnected ? 'Configure' : 'Configure';
  const shiprocketDetails = shiprocketConnected ? 'Shiprocket configured' : 'Click Configure to enable shiprocket';

  // var shopOrigin = {};
  const [thresholdPrice, setThresholdPrice] = useState(null);
  const handleThresholdChange = useCallback(newValue => setThresholdPrice(newValue), []);
  const discoveryOptions = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' }
  ];
  const [pricePerDistance, setPricePerDistance] = useState('');
  const [minimumPrice, setMinimumPrice] = useState('');
  const handleMinimumPriceChange = useCallback(newValue => setMinimumPrice(newValue), []);
  const handleChange = useCallback((newValue, id) => {
    setDistanceMatrix(prev => ({ ...prev, [id]: newValue }));
  }, []
  );
  const handlePriceChange = useCallback(newValue => setPricePerDistance(newValue), []);
  const unitOptions = [
    { label: 'Metric (kilometers)', value: 'metric' },
    { label: 'Imperial (miles)', value: 'imperial' }
  ];

  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getStoreConfigs();
    getDistanceMatrixConfigs();
    RegisterWebhook();
    // api.CheckAppUpdate(shopOrigin.current.shop).then(response => {
    //   // console.log("app update=", response.data)
    //   setAppUpdate(response.data);
    //   if (response.data == true) { generateAuthUrl(); }
    // });
    // getShiprocketConfigs();
  }, []);

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    addStoreConfigs(value);
    addDistanceMatrixConfigs();
    // console.log(value);
  }

  function handleFormToggle() {
    updateStoreStatus();
  }

  async function getDistanceMatrixConfigs() {
    showLoader();
    api.getDistanceMatrixConfigs(shopOrigin.current.shop)
      .then(items => {
        items.distanceMatrixStatus === 'Enable' ? setConnected(true) : setConnected(false)
        setPricePerDistance('' + items.pricePerDistance)
        setMinimumPrice('' + items.minimumPrice)
        setUnit(items.units)
        setDistanceMatrix({
          description: items.description,
          serviceCode: items.serviceCode,
          serviceName: items.serviceName,
          store: items.store
        })
        hideLoader();
      });
  }

  async function getShiprocketConfigs() {
    showLoader();
    api.getShipRocketConfigs(shopOrigin.current.shop)
      .then(items => {
        // console.log("shiprocket status=",items.shipRocketStatus);
        items.shipRocketStatus === 'Enable' ? setShiprocketConnected(true) : setShiprocketConnected(false)
        hideLoader();
      });
  }

  async function getStoreConfigs() {
    setPageLoad(false)
    showLoader();
    api.getStoreConfigs(shopOrigin.current.shop)
    .then(items => {
      if(items !== null)
      {
        setCarrierServiceId(items.carrierServiceId);
        var serviceDisc = items.serviceDiscovery === true ? 'true' : 'false'
        // console.log("store configs=",items);
        setSelected(serviceDisc)
        setThresholdPrice('' + items.thresholdPrice)
        items.storeStatus === "Disable" || items.storeStatus === undefined ? setActive(false) : setActive(true)
        setValue({
          store: items.store,
          storeStatus: items.storeStatus
        })
      }
    hideLoader();
    setPageLoad(true);
    });
    }

  async function addStoreConfigs(value) {
    setBtnLoad(true);
    // console.log("thprice=",thresholdPrice)
    var storeStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    var serDiscovery = selected === 'true' ? true : false;
    const data = {
      serviceDiscovery: serDiscovery,
      store: shopOrigin.current.shop,
      thresholdPrice: thresholdPrice === "null" || thresholdPrice === "undefined" ? null : thresholdPrice,
      storeStatus: storeStatus
    };
    // console.log("data = ",data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // console.log(requestOptions)
    fetch(endpoint + '/api/StoreConfig/add-update', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          // showToast();
          setBtnLoad(false);
          return response
        }
        else {
          showErrorToast();
          setBtnLoad(false);
          // throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  async function updateStoreStatus() {
    showLoader();
    var storeStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    const data = {
      store: shopOrigin.current.shop,
      storeStatus: storeStatus
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // console.log(requestOptions)
    fetch(endpoint + '/api/StoreConfig/StoreStatus', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          hideLoader();
          showToast();
          return response
        } else {
          hideLoader();
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        hideLoader();
        showErrorToast();
        return []
      })
  }

  async function RegisterWebhook() {
    // console.log(requestOptions)
    fetch(endpoint + '/api/StoreConfig/register-webhooks?storeUrl=' + shopOrigin.current.shop)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          return response
        } else {
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  function distanceConfig() {
    showLoader();
    api.getDistanceMatrixConfigs(shopOrigin.current.shop).then(response => {
      // console.log("response=",response.distanceMatrixStatus)
      if (response.distanceMatrixStatus === 'Enable' || response.distanceMatrixStatus === 'Disable' || response.distanceMatrixStatus === undefined) {
        // history.push('/distancematrix');
      }
    })
    hideLoader();
  }


  async function addDistanceMatrixConfigs() {

    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(distanceMatrixvalue.description) === true) {
      setBtnLoad(true);
      setErrorD(true)
      setErrorSn(false)
      setBtnLoad(false);
    }
    else if (format.test(distanceMatrixvalue.serviceName) === true) {
      setBtnLoad(true);
      setErrorSn(true)
      setErrorD(false)
      setBtnLoad(false)
    }
    else {
      setBtnLoad(true);
      setErrorSn(false)
      setErrorD(false)
      var distanceMatrixStatus = contentStatus === "Disable" ? "Enable" : "Disable";
      const data = {
        description: distanceMatrixvalue.description,
        pricePerDistance: pricePerDistance,
        minimumPrice: minimumPrice,
        serviceCode: distanceMatrixvalue.serviceCode,
        serviceName: distanceMatrixvalue.serviceName,
        units: unit,
        store: shopOrigin.current.shop,
        location: distanceMatrixvalue.location,
        distanceMatrixStatus: distanceMatrixStatus
      };
      // console.log("config=",data);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      console.log(requestOptions)
      fetch(endpoint + '/api/StoreConfig/add-update_dist_matrix_config', requestOptions)
        .then(function (response) {
          if (response.status === 200 && response != null) {
            showToast();
            setBtnLoad(false);
            return response
          }
          // else {
          //   showErrorToast();
          //   throw new Error('Empty data')

          // }
        })
        .catch(function (error) {
          console.log(error)
          showErrorToast();
          setBtnLoad(false);
          return []
        })
    }
  }

  function shiprocketConfig() {
    showLoader();
    api.getShipRocketConfigs(shopOrigin.current.shop).then(response => {
      // console.log("response=",response.shiprocketStatus)
      if (response.shiprocketStatus === 'Enable' || response.shiprocketStatus === 'Disable' || response.shiprocketStatus === undefined) {
        // history.push('/shiprocket');
      }
    })
    hideLoader();
  }

  const handleDistanceConfig = (evt) => {
    evt.preventDefault();
    connected ? distanceConfig() : distanceConfig();
  }

  const handleShiprocketConfig = (evt) => {
    evt.preventDefault();
    shiprocketConnected ? shiprocketConfig() : shiprocketConfig();
  }

  const warningBanner = (
    <Banner
      title="Carrier Service API is not enabled"
      action={{ content: 'Request via Shopify Chat', onAction: () => { window.open('https://help.shopify.com/en/questions#/contact') } }}
      secondaryAction={{ content: 'Learn more', onAction: () => { window.open('https://community.shopify.com/c/Shopify-Discussion/How-to-enable-Carrier-Service-API/td-p/643156') } }}
      status="warning"
    // onDismiss={() => {}}
    >
      <p>This app needs Shopify Carrier Service API to be enabled on your account.</p>
      <p>Here are ways to enable this:</p>
      <ul>
        <li>Convert to the annual plan (Basic $29 or Shopify $79) or Advanced plan($299)</li>
        <li>Request Shopify Carrier Service API which costs and additional $20 a month if you are not on any plan mentioned above.</li>
        <li>To request access, Shopify Chat can get this enabled for you in minutes</li>
      </ul>
    </Banner>
  )

  function generateAuthUrl() {
    api.generateAuthUrl(shopOrigin.current.shop).then(response => {
      setAuthUrl(response);
    })
  }

  const bannerForAppUpdate = (
    <Banner
      title="Upgrade required"
      action={{ content: 'Update', onAction: () => { window.open(authUrl) } }}
      status="warning"
      onDismiss={false}
    >
      <p>You are using an older version of this app.Please upgrade your app to continue our services. </p>
    </Banner>
  );


  return (
    <AppProvider i18n={enTranslations} >
      <Page>
        <Layout>
          <Layout.AnnotatedSection
            title="General"
            description="Customize your app settings.">
            {carrierServiceId == 0 ? warningBanner : ""}
            <Card>
              <Form>

                <Box width="100%">
                  <InlineStack
                    gap="1200"
                    align="space-between"
                    blockAlign="start"
                    wrap={false}
                  >
                    <InlineStack gap="200" wrap={false}>
                      <InlineStack gap="200" align="start" blockAlign="baseline">
                        <label htmlFor={"enable_disable"}>
                          <Text fontWeight='regular'> Easy delivery is <Badge tone={active ? "success" : "critical"}>{textStatus}</Badge></Text>
                        </label>
                      </InlineStack>
                    </InlineStack>
                    <Box minWidth="fit-content">
                      <InlineStack align="end"><Button
                        role="switch"
                        id={"enable_disable"}
                        ariaChecked={active ? 'true' : 'false'}
                        onClick={handleToggle}
                        size="slim"
                      >
                        {contentStatus}
                      </Button></InlineStack>
                    </Box>
                  </InlineStack>
                </Box>

              </Form></Card>

            <br />
            <Form onSubmit={handleFormSubmit}>
              <Card>
                <FormLayout>
                  <TextField
                    label="Your preferred service description"
                    placeholder="Fast Delivery"
                    helpText="Description for your delivery service (optional)"
                    id="description"
                    value={distanceMatrixvalue.description}
                    onChange={handleChange}
                    error={errorD === false ? '' : 'Special characters are not allowed'}
                  />
                  <FormLayout.Group condensed>
                    <TextField
                      label="Your preferred service name"
                      placeholder="Example Delivery"
                      helpText="Name for your delivery service (optional)"
                      id="serviceName"
                      value={distanceMatrixvalue.serviceName}
                      onChange={handleChange}
                      error={errorSn === false ? '' : 'Special characters are not allowed'}
                    />
                    <TextField
                      label="Your preferred service code"
                      placeholder="SC"
                      helpText="Unique service code for your delivery service (optional)"
                      id="serviceCode"
                      value={distanceMatrixvalue.serviceCode}
                      onChange={handleChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <Select
                      label="Select Units"
                      id="units"
                      options={unitOptions}
                      onChange={handleUnitChange}
                      value={unit}
                    />
                    <TextField
                      label="Enter the price per distance"
                      type="number"
                      placeholder="10"
                      helpText="Delivery rates per distance"
                      id="pricePerDistance"
                      value={pricePerDistance}
                      onChange={handlePriceChange}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group condensed>
                    <Select
                      label="Select Service Discovery"
                      id="serviceDiscovery"
                      helpText="Use Easy Delivery for Shipping Calculator on the cart page"
                      options={discoveryOptions}
                      onChange={handleSelectChange}
                      value={selected}
                    />
                    <TextField
                      label="Enter the threshold price"
                      type="number"
                      placeholder=""
                      helpText="Threshold for free shipping (leave blank if not applicable)"
                      id="thresholdPrice"
                      value={thresholdPrice}
                      onChange={handleThresholdChange}
                    />
                  </FormLayout.Group>
                  <TextField
                    label="Enter the minimum price"
                    type="number"
                    placeholder="10"
                    helpText="Enter the minimum price required for delivery. If the calculated delivery price is below this value, the minimum price will be charged."
                    id="minimumRate"
                    value={minimumPrice}
                    onChange={handleMinimumPriceChange}
                  />
                </FormLayout>
              </Card>

              <PageActions
                primaryAction={{
                  content: 'Save',
                  loading: btnLoad,
                  submit: true,
                }} />
            </Form>
          </Layout.AnnotatedSection>
        </Layout>
        {toastMarkup}
        {toastErrorMarkup}
        {loader}
      </Page>
    </AppProvider>
  );
}
