import { BlockStack, Icon, Tabs, Text } from "@shopify/polaris";
import React, {useCallback, useState} from 'react';
import { GlobalSetting } from "./GlobalSetting";
import { NoteIcon,SettingsIcon, ContractIcon } from '@shopify/polaris-icons';
import { Logs } from "./Logs";
import { PrivacyPolicy } from "./PrivacyPolicy";

export function Home() 
{
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "settings",
      content: <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Icon source={SettingsIcon} tone="base" />
        <span style={{ paddingLeft: "5px" }}>Settings</span>
      </div>,
      panelID: "settings",
      componentRender: <GlobalSetting/>,
    },
    {
      id: "logs",
      content: <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <Icon source={NoteIcon} tone="base" />
        <span style={{ paddingLeft: "5px" }}>Logs</span>
      </div>,
      panelID: "logs",
      componentRender: <Logs/>,
    },
    {
      id: "privacy",
      content: <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Icon source={ContractIcon} tone="base" />
      <span style={{ paddingLeft: "5px" }}>Privacy</span>
      </div>,
      panelID: "privacy",
      componentRender: <PrivacyPolicy/>
    }
  ];

  return (
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <BlockStack gap="400">
          <BlockStack gap="200">
            <Text as="h2" variant="headingSm">
              {tabs[selected].componentRender}
            </Text>
          </BlockStack>
        </BlockStack>
      </Tabs>
  );
}