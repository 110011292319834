import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Page, Layout, Card, FormLayout, TextField, PageActions, SettingToggle, Form, Select, Text } from '@shopify/polaris';
import { environment } from './environments/environment.prod';
import useLoader from './Loader/UseLoader';
import useSuccessToast from './Toast/UseSuccessToast';
import useErrorToast from './Toast/UseErrorToast';
import Api from './Services/CommonServices';
import { ConfigEmptyState } from './ConfigEmptyState';
import * as storage from "./Auth/storage";
const api = new Api();
const endpoint = environment.serverUrl;
export function DistanceMatrix() {

  const shopOrigin = useRef('');
  // var shopOrigin = {};
  const [active, setActive] = useState(false);
  const [btnLoad, setBtnLoad] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  // const [shopUrl,setShopUrl] = useState('');
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [errorD, setErrorD] = useState(false);
  const [errorSn, setErrorSn] = useState(false);
  const [unit, setUnit] = useState('metric');
  const handleUnitChange = useCallback((value) => setUnit(value), []);
  const [value, setValue] = useState({
    description: '',
    serviceCode: '',
    serviceName: '',
    store: '',
    serviceDiscovery: true,
    units: ''
  });
  const [pricePerDistance, setPricePerDistance] = useState('');
  const handleChange = useCallback((newValue, id) => {
    setValue(prev => ({ ...prev, [id]: newValue }));
  }, []
  );
  const handlePriceChange = useCallback(newValue => setPricePerDistance(newValue), []);
  const unitOptions = [
    { label: 'Metric (kilometers)', value: 'metric' },
    { label: 'Imperial (miles)', value: 'imperial' }
  ];

  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    // shopOrigin = shopOrigin.current.shop;
    // setShopUrl(shopOrigin)
    getDistanceMatrixConfigs();
  }, [active]);


  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    addStoreConfigs(value);
    // console.log(value);
  }

  const handleFormToggle = (evt) => {
    evt.preventDefault();
    updateStoreStatus(value);
  }
  async function getDistanceMatrixConfigs() {

    // getStore();
    showLoader();
    api.getDistanceMatrixConfigs(shopOrigin.current.shop)
      .then(items => {
        items.distanceMatrixStatus === "Disable" || items.distanceMatrixStatus === undefined ? setActive(false) : setActive(true)
        // console.log("status=",items.distanceMatrixStatus);
        setPricePerDistance('' + items.pricePerDistance)
        setUnit(items.units)
        setValue({
          description: items.description,
          serviceCode: items.serviceCode,
          serviceName: items.serviceName,
          store: items.store,
        })
        // setActive(items.distanceMatrixStatus === "Disable" ? false : true)
        hideLoader();
      });
  }

  async function addStoreConfigs(value) {

    var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (format.test(value.description) === true) {
      setBtnLoad(true);
      setErrorD(true)
      setErrorSn(false)
      setBtnLoad(false);
    }
    else if (format.test(value.serviceName) === true) {
      setBtnLoad(true);
      setErrorSn(true)
      setErrorD(false)
      setBtnLoad(false)
    }
    else {
      setBtnLoad(true);
      setErrorSn(false)
      setErrorD(false)
      var distanceMatrixStatus = contentStatus === "Disable" ? "Enable" : "Disable";
      const data = {
        description: value.description,
        pricePerDistance: pricePerDistance,
        serviceCode: value.serviceCode,
        serviceName: value.serviceName,
        units: unit,
        store: shopOrigin.current.shop,
        location: value.location,
        distanceMatrixStatus: distanceMatrixStatus
      };
      // console.log("config=",data);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };
      // console.log(requestOptions)
      fetch(endpoint + '/api/StoreConfig/add-update_dist_matrix_config', requestOptions)
        .then(function (response) {
          if (response.status === 200 && response != null) {
            showToast();
            setBtnLoad(false);
            return response
          }
          // else {
          //   showErrorToast();
          //   throw new Error('Empty data')

          // }
        })
        .catch(function (error) {
          console.log(error)
          return []
        })
    }
  }

  async function updateStoreStatus(value) {
    var distanceMatrixStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    const data = {
      store: shopOrigin.current.shop,
      distanceMatrixStatus: distanceMatrixStatus
    };
    // console.log("config=",data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // console.log(requestOptions)
    fetch(endpoint + '/api/StoreConfig/distance_matrix_status', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          getDistanceMatrixConfigs();
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }



  return (
    // <AppProvider i18n={enTranslations}>
    // <Page breadcrumbs={[{content: 'Settings', onAction: redirect }]}>
    <Page>
      <Layout>
        <Layout.AnnotatedSection
          title="General"
          description="Configure Delivery by Distance settings.">
          <Card>
            <Form onSubmit={handleFormToggle}>
              <SettingToggle
                action={{
                  content: contentStatus,
                  onAction: handleToggle,
                  submit: true
                }}
                enabled={active}>
                Delivery by Distance is <Text fontWeight="regular">{textStatus}</Text>.
              </SettingToggle>
            </Form>
          </Card>
          <Card></Card>
          {/* { active === true ?  */}
          <Form onSubmit={handleFormSubmit}>
            <Card sectioned>
              <FormLayout>
                <TextField label="Your preferred service description" placeholder="Fast Delivery" helpText="Description for your delivery service (optional)" id="description" value={value.description} onChange={handleChange} error={errorD === false ? '' : 'special characters are not allowed'} />
                <FormLayout.Group>
                  <TextField label="Your preferred service name" placeholder="Example Delivery" helpText="Name for your delivery service (optional)" id="serviceName" value={value.serviceName} onChange={handleChange} error={errorSn === false ? '' : 'special characters are not allowed'} />
                  <TextField label="Your preferred service code" placeholder="SC" helpText="Unique service code for your delivery service (optional)" id="serviceCode" value={value.serviceCode} onChange={handleChange} />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Select label="Select Units" id="units" options={unitOptions} onChange={handleUnitChange} value={unit} />
                  <TextField label="Enter the price per distance" type="number" placeholder="10" helpText="Delivery rates per distance" id="pricePerDistance" value={pricePerDistance} onChange={handlePriceChange} />
                </FormLayout.Group>
              </FormLayout>
            </Card>
            <PageActions
              primaryAction={{
                content: 'Save',
                loading: btnLoad,
                submit: true,
              }} />
          </Form>
          {/* : <ConfigEmptyState/>} */}
        </Layout.AnnotatedSection>
      </Layout>
      {toastMarkup}
      {toastErrorMarkup}
      {loader}
    </Page>

    // </AppProvider>
  );
}
