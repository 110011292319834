import React, {useCallback, useState,useEffect, useRef} from 'react';
// import '@shopify/polaris/dist/styles.css';
import { Page,Layout,Card,FormLayout,TextField,PageActions, SettingToggle,Form, Checkbox, Text} from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json'
import { AppProvider } from '@shopify/polaris';
import { environment } from './environments/environment.prod';
import useLoader from './Loader/UseLoader';
import useSuccessToast from './Toast/UseSuccessToast';
import useErrorToast from './Toast/UseErrorToast';
import Api from './Services/CommonServices';
import * as storage from "./Auth/storage";
import { unstable_HistoryRouter } from 'react-router-dom';
const api = new Api();
const endpoint = environment.serverUrl;
export function ShipRocket() {
  const history = unstable_HistoryRouter();
  const [active, setActive] = useState(false);
  const [loader,showLoader,hideLoader] = useLoader();
  const [toastMarkup,showToast] = useSuccessToast();
  const [toastErrorMarkup,showErrorToast] = useErrorToast();
  const [btnLoad, setBtnLoad] = useState();
//   const [shopUrl,setShopUrl] = useState('');
  // var shopUrl = {};
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [checkboxDisable, setCheckboxDisable] = useState(true);
  const [cod, setCod] = useState(false);
  const handleCodChange = useCallback((newChecked) => setCod(newChecked), []);
  const [modeAir, setModeAir] = useState(false);
  const [modeSurface, setModeSurface] = useState(false);
  const handleModeAirChange = useCallback((newChecked) => setModeAir(newChecked), []);
  const handleModeSurfaceChange = useCallback((newChecked) => setModeSurface(newChecked), []);
  const [courierStatus, setCourierStatus] = useState(false);
  const handleCourierChange = useCallback((newChecked) => setCourierStatus(newChecked), []);
  const [error,setError] = useState('');
  const shopOrigin = useRef('');
  const [value, setValue] = useState({
    shipRocketCod:'',
    shipRocketStatus:'Disable',
    email:'',
    password:''
  });
  // var shopOrigin = {};
  const handleChange = useCallback( (newValue,id) => {
    setValue(prev => ({ ...prev, [id]: newValue }));
  },[]
  );

  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getShipRocketConfigs();
  }, []);
  const handleFormSubmit =(evt) =>{
  evt.preventDefault();
  addShiprocketConfigs(value);
  // console.log(value);
  }
  const handleFormToggle =(evt) =>{
    evt.preventDefault();
    updateShipRocketStatus(value);
    // console.log("handleFormtoggle",value);
    }

  async function getShipRocketConfigs() 
   {
    showLoader();
    // console.log("inside config=",shopUrl);
    api.getShipRocketConfigs(shopOrigin.current.shop)
      .then(items => {
          // console.log(items);
        // if (items.shipRocketStatus=="Disable"){setActive(false)}
        // else { setActive(true)}
        // items.shipRocketStatus === "Disable" ? setActive(false) : setActive(true)
        var status = items.shipRocketStatus === "Disable" || items.shipRocketStatus === undefined ? setActive(false) : setActive(true)
        setValue({
          shipRocketStatus:status,
          email:items.shiprocketEmail,
          password:items.shiprocketPassword
      })
      setCod(items.shipRocketCod);
      setModeAir(items.modeAir)
      setModeSurface(items.modeSurface)
      hideLoader();
    });
    getShiprocketAuth();
  }
  async function getShiprocketAuth() 
  {
   showLoader();
   api.getShiprocketAuth(shopOrigin.current.shop)
     .then(items => {
        //  console.log(items);
       if (items.intlCourierStatus===false){setCheckboxDisable(true)}
       else { setCheckboxDisable(false)}
     hideLoader();
   });
 }
async function addShiprocketConfigs(value)
{
  // console.log("value=",value.email);
  if(value === null || value.email === null  || value.password === null || value.email === "" || value.password === "")
  {
    setBtnLoad(true);
    setError("is required");
    setBtnLoad(false);
  }
  else{
  setBtnLoad(true);
  var shipRocketStatus = contentStatus === "Disable" ? "Enable" : "Disable";
  const data = {
        shiprocketEmail:value.email,
        shiprocketPassword:value.password,
        shipRocketCod:cod,
        modeAir:modeAir,
        modeSurface:modeSurface,
        store:shopOrigin.current.shop,
        shipRocketStatus: shipRocketStatus
  };
  // console.log("values=",data);
const requestOptions = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
};
// console.log(requestOptions)
fetch(endpoint +'/api/StoreConfig/add-update_shiprocket_config', requestOptions)
.then(function(response) {
if (response.status === 200 && response != null) {
  setBtnLoad(false);
  showToast();
  return response
} else {
  showErrorToast();
  setBtnLoad(false);
  throw new Error('Empty data')
  
}
})
.catch(function(error) {
showErrorToast();
console.log(error)
setBtnLoad(false);
return [] 
})
  }
}
async function updateShipRocketStatus(value)
  {
    if(value === null || value.email === null  || value.password === null || value.email === "" || value.password === "")
    {
      setError("is required");
    }
    else{
    var shipRocketStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    const data = {
          store:shopOrigin.current.shop,
          shipRocketStatus: shipRocketStatus
    };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
};
// console.log(requestOptions)
fetch(endpoint +'/api/StoreConfig/shiprocket_status', requestOptions)
.then(function(response) {
  if (response.status === 200 && response != null) {
    showToast();
    return response
  } else {
    showErrorToast();
    throw new Error('Empty data')
    
  }
})
.catch(function(error) {
  console.log(error)
  return [] 
})
    }
}

function redirect()
  {
    history.push('/')
  }

  // const config = {apiKey: environment.apiKey, shopOrigin:shopUrl};
  
  return (
    <AppProvider i18n={enTranslations}>
        {/* <Page breadcrumbs={[{content: 'Settings', onAction:redirect}]}> */}
          <Page>
            <Layout>
              <Layout.AnnotatedSection
                title="Ship Rocket"
                description="Customize your integration settings.">
                  <Form onSubmit={handleFormToggle}>
                  <SettingToggle
                      action={{
                        content: contentStatus,
                        onAction: handleToggle,
                        submit:true
                      }}
                        enabled={active}>
                        Ship Rocket is <Text as="strong">{textStatus}</Text>.
                    </SettingToggle>
                    </Form>
                    
                <Form onSubmit={handleFormSubmit}>
                <Card sectioned>
                  <FormLayout>
                  <FormLayout.Group>
                  <TextField label="Your ship rocket email" placeholder="example@example.com" helpText="Registered ship rocket email" id="email" value={value.email}  onChange={handleChange} error={error}/>
                  <TextField type="password"  label="Your ship rocket password" placeholder="********" helpText="Your ship rocket account password" id="password" value={value.password} onChange={handleChange} error={error}/>
                  </FormLayout.Group>
                  </FormLayout>
                  <FormLayout>
                    <Text as='h1'>Options</Text>
                      <FormLayout.Group>
                    <Checkbox
                    label="COD"
                    id="cod"
                    helpText="Enable/Disable Cash On Delivery"
                    checked={cod}
                    onChange={handleCodChange}
                    />
                    <Checkbox
                    label="Air"
                    id="air"
                    helpText="Enable/Disable the mode of travel"
                    checked={modeAir}
                    onChange={handleModeAirChange}
                    />
                  </FormLayout.Group>
                  </FormLayout>
                  <FormLayout>
                  <FormLayout.Group>
                    <Checkbox
                    label="Surface"
                    id="surface"
                    helpText="Enable/Disable the mode of travel"
                    checked={modeSurface}
                    onChange={handleModeSurfaceChange}
                    />
                    <Checkbox
                    label="International Courier Service(on advance and above plans)"
                    id="InternationalCourier"
                    helpText="Enable/Disable International Courier Service"
                    disabled = {checkboxDisable}
                    checked={courierStatus}
                    onChange={handleCourierChange}
                    />
                  </FormLayout.Group>
                  </FormLayout>
                  </Card>
                  <PageActions
                  primaryAction={{
                  content: 'Save',
                  loading:btnLoad,
                  submit: true,
                  }} />
                  </Form>
              </Layout.AnnotatedSection>
              <Layout.Section>
              
              </Layout.Section>
            </Layout>
            {toastMarkup}
            {toastErrorMarkup}
            {loader}
        </Page>
     
    </AppProvider>
  );
}
