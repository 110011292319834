import React, { Component } from 'react';

export class Layout1 extends Component {
  static displayName = Layout1.name;

  render () {
    
    return (
      
      <div>
          {this.props.children}
      </div>
    );
    
  }
}
