import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { Layout1 } from './components/Layout1';
import { Logs } from './components/Logs';
import { VerifyShop } from './components/VerifyShop';
import { requireAuth } from './components/Auth/auth';
import { AppProvider, Frame } from '@shopify/polaris';
// import './custom.css'
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { DistanceMatrix } from './components/DistanceMatrix';
import { ShipRocket } from './components/ShipRocket';
import { GlobalSetting } from './components/GlobalSetting';
import { environment } from './components/environments/environment.prod';
import {Provider} from '@shopify/app-bridge-react';
import { Home } from './components/Home';
// import * as storage from "./components/Auth/storage";
const auth = new requireAuth();
const apikey = environment.apiKey;
export function App() {
  // static displayName = App.name;
  const config = {
    apiKey: apikey,
    host: new URLSearchParams(window.location.search).get("host")
  };
  // render () {
    return (
      <AppProvider>
        <Provider config={config}>
        <Frame>
          <Layout1>
        <Routes>
        <Route path='/privacy' element={<PrivacyPolicy/> } />
      {/* <Layout1> */}
        {/* <Route path='/VerifyShop' component={VerifyShop} /> */}
        {/* <Redirect from= '/' to= '/settings' /> */}
        {/* <Route exact path='/settings' component={GlobalSetting}  />
        <Route path='/distancematrix' component={DistanceMatrix} />
        <Route path='/shiprocket' component={ShipRocket} />
        <Route path='/logs' component={Logs} />
        <Route path='/privacy' component={PrivacyPolicy} />
      </Layout1> */}
      <Route  path='/' element={<Home/>}   />
      <Route  path='/verify-shop' element={<VerifyShop/>} />
      {/* <Route exact path='/' component={GlobalSetting} appProps={  auth.canActivate() }  />
        <Route path='/VerifyShop' component={VerifyShop} />
        <Route path='/distancematrix' component={DistanceMatrix} />
        <Route path='/shiprocket' component={ShipRocket} />
        <Route path='/logs' component={Logs} /> */}
        
      {/* </Layout1> */}
      </Routes>
      </Layout1>
      </Frame>
      </Provider>
      </AppProvider>
    );
  // }
}
