// import { env } from './.env';

export const environment = {
  production: true,
  // version: env.npm_package_version,
  // serverUrl: 'https://easy-delivery-3ncuuzlm4a-uc.a.run.app',
  serverUrl: 'https://easy-delivery.claritytechnologies.com',
  appName: 'clarity-easy-delivery',
  apiKey:'cfcbfc8e0754192013728d01da2a2e9b'
};
