import React, { useState, useCallback } from 'react';
import { Frame, Page, Toast } from '@shopify/polaris';
const useSuccessToast =() => {
    const [active, setActive] = useState(false);
    const showToast = useCallback(() => setActive(active => !active), []);
    const toastMarkup = active ? (
    <div style={{ height: '250px' }}>
    <Frame>
      <Page><Toast content="Saved" onDismiss={showToast} duration={3000} />
      </Page>
      </Frame>
    </div>) : null;
    
return[toastMarkup,showToast];
}
export default useSuccessToast;