import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as ReactDOMClient from 'react-dom/client';
import { App } from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

