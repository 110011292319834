import axios from 'axios';
import { environment } from '../environments/environment.prod';
// import { environment } from '../environments/environment';
const endpoint = environment.serverUrl;
  class Api {

    getShiprocketAuth=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/SRAuthorizatiaon?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    shipRocketAuth=(username,password,cod,shopUrl)=> {
      // console.log("auth service=",shopUrl);
      return axios
      .post(endpoint+'/api/SRAuthorizatiaon?userEmail='+username+'&password='+password+'&cod='+cod+'&shopUrl='+shopUrl)
      //.get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        // console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    generateAuthUrl=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/Store/get-auth-url?storeUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    createRecurringCharge(shopUrl) {
      return axios
      .get(endpoint+'/api/RecurringCharge/add?storeUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    verifyShop=(queryString) =>{
      if (!queryString) {
        queryString = this.queryString;
      }
      return axios
      .get(endpoint+'/api/store/verify-request' + queryString)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    checkIsShopExist=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/Store/IsStoreExists?storeUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }

    chargeStatus=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/RecurringCharge/chargestatus?shopUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    addCharge=(shopUrl)=> {
      return axios
      .get(endpoint+'/api/RecurringCharge/create?storeUrl=' + shopUrl)
      .then(function(response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
    }
    getStoreConfigs = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/StoreConfig/storeconfigs-of-shop?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            return null
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

    getDistanceMatrixConfigs = (shopUrl) => {
      // console.log(shopUrl);
      return axios
        .get(endpoint+'/api/StoreConfig/distancematrixconfigs-of-shop?shopUrl='+ shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          // console.log(error)
          return [] // Return empty array in case error response.
        })
    }
    getShipRocketConfigs = (shopUrl) => {
      // console.log(shopUrl);
      const url = "/api/StoreConfig/shiprocketconfigs-of-shop"
      return axios
        .get(endpoint+url+'?shopUrl='+ shopUrl)
        
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }
    
    getStore = (shopUrl) => {
      return axios
        .get(endpoint+'/api/Store?storeUrl='+shopUrl)
        .then(function(response) {
          // console.log(response)
          if (response.status === 200 && response != null) {
            var data = response.data
            return data
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function(error) {
          console.log(error)
          return [] // Return empty array in case error response.
        })
    }

      getActivityLogs = (shopUrl) => {
        // console.log("shop=",shopUrl);
        return axios
          .get(endpoint+'/api/DistanceMatrix/activitylogs?shopUrl='+shopUrl)
          .then(function(response) {
            // console.log(response)
            if (response.status === 200 && response != null) {
              // var data = response.data
              return response
            } else {
              throw new Error('Empty data')
            }
          })
          .catch(function(error) {
            console.log(error)
            return [] // Return empty array in case error response.
          })
      }
      getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }

      QueryStringToJSON() {
        let queryString = "";
        queryString = window.location.search;
        // console.log(`query string = ${queryString}`);
        if (!queryString) {
          queryString = this.queryString;
        }
        let result = {};
        if(queryString) {
          this.queryString = queryString;
          const pairs = queryString.replace('?', '').split('&');

          pairs.forEach(function(pair) {
              pair = pair.split('=');
              result[pair[0]] = decodeURIComponent(pair[1] || '');
          });
        }

        return JSON.parse(JSON.stringify(result));
      }

      getLogsPaginate = (shopUrl,pageNo,pageSize) => {
        return axios
          .get(endpoint+'/api/DistanceMatrix/paginate?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize)
         
          .then(function(response) {
            if (response.status === 200 && response != null) {
              // var data = response.data
              return response
            } else {
              throw new Error('Empty data')
            }
          })
          .catch(function(error) {
            console.log(error)
            return [] 
          })
      }
      getSortedLogs(shopUrl,pageNo,pageSize,sortOrder){
      return axios
      .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .then(function(response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function(error) { 
        console.log(error)
        return [] 
      })
    }
    
    CheckAppUpdate(storeUrl) {
      return axios
        .get(endpoint + '/api/Store/need-app-update?storeUrl=' + storeUrl)
        .then(function (response) {
          if (response.status === 200 && response != null) {
            return response
          } else {
            throw new Error('Empty data')
          }
        })
        .catch(function (error) {
          console.log(error)
          return []
        })
    }
  }
  export default Api
