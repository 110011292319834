import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, ResourceItem, Page, AppProvider, EmptyState, Text, InlineStack, DataTable } from '@shopify/polaris';
import Api from './Services/CommonServices';
import useLoader from './Loader/UseLoader';
import enTranslations from '@shopify/polaris/locales/en.json';
const api = new Api();
export function Logs() {

    // var shopOrigin.current.shop = {};
    const [logs, setLogs] = useState([]);
    const [loader, showLoader, hideLoader] = useLoader();
    const pageSize = 10;
    let [pageNo, setPageNo] = useState(1);
    const [nxtButton, setNxtButton] = useState(true);
    const [prvButton, setPrvButton] = useState(true);
    // shopOrigin.current.shop = shopOrigin.current.shop;
    const [selected, setSelected] = useState('time_latest');
    const [pageCount, setPageCount] = useState(0);
    const shopOrigin = useRef('');
    let handleSelectChange = useCallback((value) => setSelected(value));
    const options = [
        // {label: 'Show all', value: 'ALL'},
        { label: 'Newest', value: 'time_latest' },
        { label: 'Oldest', value: 'time_oldest' },
        { label: 'Nearest', value: 'distance_nearest' },
        { label: 'Farthest', value: 'distance_farthest' },
    ];
    const resourceName = {
        singular: 'log',
        plural: 'logs'
    };
    useEffect(() => {
        shopOrigin.current = api.QueryStringToJSON();
        getSortedLogs();
        getActivityLogs();
    }, [selected], [logs]);

    function getSortedLogs() {
        if (pageNo > 0) {
            showLoader();
            api.getSortedLogs(shopOrigin.current.shop, pageNo, pageSize, selected).then(response => {
                if (response.data.length !== 0) {
                    setLogs(response.data);
                }
                // setPageCount(Math.ceil(response.data / pageSize))
                hideLoader();
            })
        }
    }

    function getActivityLogs() {
        showLoader();
        api.getActivityLogs(shopOrigin.current.shop).then(response => {
            // setValue(response.data);
            // console.log(response.data);
            setPageCount(Math.ceil(response.data / pageSize))
            hideLoader();
        })
    }

    function next() {
        pageNo = pageNo + 1
        if (pageNo <= pageCount) {
            setPageNo(pageNo);
            getSortedLogs();
            // console.log("page=",pageNo)
            setPrvButton(true);
        }
        else { setNxtButton(false); setPrvButton(true); }
    }
    function prev() {
        pageNo =  pageNo - 1 <= 0 ? 1 : pageNo - 1;
        if (pageNo >= 1) {
            setPageNo(pageNo);
            getSortedLogs();
            // console.log("page=",pageNo)
            setNxtButton(true)
        }
        else { setPrvButton(false); setNxtButton(true) }
    }

    const emptyStateMarkup =
        logs.length == 0 ? (
            <EmptyState>
                <p>
                    There's nothing to show here.
                </p>
            </EmptyState>
        ) : undefined;

    const logsList = (
        <div>
            <Card>
                {/* <ResourceList
                    resourceName={resourceName}
                    emptyState={emptyStateMarkup}
                    showHeader={true}
                    totalItemsCount={pageCount}
                    items={logs}
                    renderItem={renderItem}
                    headerContent='Logs'
                    sortValue={selected}
                    sortOptions={options}
                    onSortChange={handleSelectChange}
                /> */}
        <DataTable
          columnContentTypes={[
            'text',
            'text',
            'text',
          ]}
          headings={[
            'Destination',
            'Distance',
            'Status'
          ]}
          rows={logs.map(log => [
            log.destinationAddress[0],
            log.distance,
            log.status
          ])}
          
                    pagination={{
                        hasPrevious: prvButton,
                        onPrevious: () => {
                            if (logs.length !== 0) {
                                prev();
                            }
                        },
                        hasNext: nxtButton,
                        onNext: () => {
                            if (logs.length !== 0) {
                                next();
                            }
                        }
                    }}
        
        />
            </Card> 
        </div>
    );


    return (
        <Page>
            {loader}
            {logsList}
        </Page>
    );

    function renderItem(item) {
        const { destinationAddress, distance, status } = item;
        return (
            <AppProvider i18n={enTranslations}>
                <ResourceItem key={destinationAddress} >
                    <InlineStack align='start' blockAlign='baseline' gap={'800'}>
                        <Text fontWeight='bold'>{destinationAddress}</Text>
                        <Text fontWeight="bold">{distance}</Text>
                        <Text fontWeight="bold">{status}</Text>
                    </InlineStack >
                </ResourceItem>
            </AppProvider>
        );
    }
}
